<template>
  <div class="useful-articles">
    <div class="container">
      <h3 class="title"></h3>
      <div class="useful-articles__inner">
        <div class="useful-articles__item">
          <div class="useful-articles__content">
            <p class="useful-articles__date">29 июня 2022</p>
            <h6 class="useful-articles__title">
              30 алкогольных коктейлей на все случаи жизни
            </h6>
            <a class="useful-articles__link" href="#?">
              Читать подробнее
              <img src="@/assets/img/icons/arrow-rigth-black.svg" alt="" />
            </a>
          </div>
          <img src="@/assets/img/usefularticle/1.png" class="useful-articles__image"> 
        </div>
        <div class="useful-articles__item">
          <div class="useful-articles__content">
            <p class="useful-articles__date">29 июня 2022</p>
            <h6 class="useful-articles__title">
              30 алкогольных коктейлей на все случаи жизни
            </h6>
            <a class="useful-articles__link" href="#?">
              Читать подробнее
              <img src="@/assets/img/icons/arrow-rigth-black.svg" alt="" />
            </a>
          </div>
          <img src="@/assets/img/usefularticle/2.png" class="useful-articles__image"> 
        </div>
        <div class="useful-articles__item">
          <div class="useful-articles__content">
            <p class="useful-articles__date">29 июня 2022</p>
            <h6 class="useful-articles__title">
              30 алкогольных коктейлей на все случаи жизни
            </h6>
            <a class="useful-articles__link" href="#?">
              Читать подробнее
              <img src="@/assets/img/icons/arrow-rigth-black.svg" alt="" />
            </a>
          </div>
          <img src="@/assets/img/usefularticle/2.png" class="useful-articles__image"> 
        </div>
        <div class="useful-articles__item">
          <div class="useful-articles__content">
            <p class="useful-articles__date">29 июня 2022</p>
            <h6 class="useful-articles__title">
              30 алкогольных коктейлей на все случаи жизни
            </h6>
            <a class="useful-articles__link" href="#?">
              Читать подробнее
              <img src="@/assets/img/icons/arrow-rigth-black.svg" alt="" />
            </a>
          </div>
          <img src="@/assets/img/usefularticle/2.png" class="useful-articles__image"> 
        </div>
        <div class="useful-articles__item">
          <div class="useful-articles__content">
            <p class="useful-articles__date">29 июня 2022</p>
            <h6 class="useful-articles__title">
              30 алкогольных коктейлей на все случаи жизни
            </h6>
            <a class="useful-articles__link" href="#?">
              Читать подробнее
              <img src="@/assets/img/icons/arrow-rigth-black.svg" alt="" />
            </a>
          </div>
          <img src="@/assets/img/usefularticle/2.png" class="useful-articles__image"> 
        </div>
        <div class="useful-articles__item">
          <div class="useful-articles__content">
            <p class="useful-articles__date">29 июня 2022</p>
            <h6 class="useful-articles__title">
              30 алкогольных коктейлей на все случаи жизни
            </h6>
            <a class="useful-articles__link" href="#?">
              Читать подробнее
              <img src="@/assets/img/icons/arrow-rigth-black.svg" alt="" />
            </a>
          </div>
          <img src="@/assets/img/usefularticle/2.png" class="useful-articles__image"> 
        </div>
        <div class="useful-articles__item">
          <div class="useful-articles__content">
            <p class="useful-articles__date">29 июня 2022</p>
            <h6 class="useful-articles__title">
              30 алкогольных коктейлей на все случаи жизни
            </h6>
            <a class="useful-articles__link" href="#?">
              Читать подробнее
              <img src="@/assets/img/icons/arrow-rigth-black.svg" alt="" />
            </a>
          </div>
          <img src="@/assets/img/usefularticle/2.png" class="useful-articles__image"> 
        </div>
        <div class="useful-articles__item">
          <div class="useful-articles__content">
            <p class="useful-articles__date">29 июня 2022</p>
            <h6 class="useful-articles__title">
              30 алкогольных коктейлей на все случаи жизни
            </h6>
            <a class="useful-articles__link" href="#?">
              Читать подробнее
              <img src="@/assets/img/icons/arrow-rigth-black.svg" alt="" />
            </a>
          </div>
          <img src="@/assets/img/usefularticle/2.png" class="useful-articles__image"> 
        </div>
        <div class="useful-articles__item">
          <div class="useful-articles__content">
            <p class="useful-articles__date">29 июня 2022</p>
            <h6 class="useful-articles__title">
              30 алкогольных коктейлей на все случаи жизни
            </h6>
            <a class="useful-articles__link" href="#?">
              Читать подробнее
              <img src="@/assets/img/icons/arrow-rigth-black.svg" alt="" />
            </a>
          </div>
          <img src="@/assets/img/usefularticle/2.png" class="useful-articles__image"> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppUsefulArticles",
};
</script>

<style lang="scss" scoped></style>
